<template>
    <div class="card">
        <div class="card-header">
            <button class="btn btn-danger" @click="back">
                <i class="zmdi zmdi-arrow-back"></i>
            </button>
        </div>
        <div class="card-body">
            <div v-if="isProcessing">
                <Processing />
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-4">
                        <img :src="user.photo != '' ? user.photo : 'img/placeholder.jpg' " alt="" class="user__image">
                    </div>
                    <div class="col-8">
                        <dl>
                            <dt>Name</dt>
                            <dd>{{ user.fullname }}</dd>
                        </dl>
                        <dl>
                            <dt>Email</dt>
                            <dd>{{ user.email }}</dd>
                        </dl>
                        <dl>
                            <dt>Role</dt>
                            <dd>{{ user.role }}</dd>
                        </dl>
                        <dl>
                            <dt>Telephone</dt>
                            <dd>{{ user.telephone }}</dd>
                        </dl>
                        <dl>
                            <dt>Date of Birth</dt>
                            <dd>{{ user.dob }}</dd>
                        </dl>
                        <dl>
                            <dt></dt>
                            <dd>{{ user.gender }}</dd>
                        </dl>
                        <dl>
                            <dt>Added On</dt>
                            <dd>{{ user.createdAt }}</dd>
                        </dl>
                        <dl>
                            <dt>Added By</dt>
                            <dd>{{ user.createdBy.email }}</dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            user: {},
            isProcessing: false,
        }
    },
    created(){
        this.viewUser()
    },
    methods: {

        async viewUser(){
            this.isProcessing = true
            const snapshot = await this.$db.collection("users").doc(this.$route.params.userId).get()
            const data = snapshot.data()
            data.dob = data.dob && data.dob != null ? this.$moment(data.dob.toDate()).format('DD-MM-YYYY') : "NIL"
            data.createdAt = data.createdAt && data.createdAt != null ? this.$moment(data.createdAt.toDate()).format('DD-MM-YYYY HH:mm:ss a') : "NIL"
            this.user = data
            this.isProcessing = false
        },

        back(){
            this.$router.go(-1)
        },
        edit(id){
            this.$router.push("/users/u/" + id + "/edit")
        },
    },
    components: {
        "Processing": () => import("@/components/Loaders/Processing.vue"),
    }
}
</script>

<style scoped>
.user__image {
    width: 200px;
    height: 200px;
    object-fit: cover;
}
</style>